import React from "react";
import TableLayout from "../components/layout/TableLayout";
import AppBarComponent from "../components/AppBarComponent";
import AddStudentForm from "../components/AddStudentForm";
import { Box } from "@mui/material";

export default function StudentsInfoAdd() {
  return (
    <Box sx={{height: '100%'}}>
      <AppBarComponent handlePrint={() => {}} enablePrint={false} />
      <TableLayout>
        <AddStudentForm />
      </TableLayout>
    </Box>
  );
}
