import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import translation from "../locales/pl.json";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebase/firebaseInit";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface StudentsInfoInterface {
  first_name: string;
  second_name: string;
  birth_place: string;
  birth_date: string;
  citizenship: string;
  family_status: number;
  pesel: string;
  brak: number;
  first_education: string;
  international_passport: string;
  university: string;
  university_rector: string;
}

export default function AddStudentForm() {
  const sha256 = require("js-sha256");
  const rows = translation.translation.table_rows;
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const secretKey = `${t("web-name")}`;
  const [studentsInfo, setStudentsInfo] = React.useState<any>({});
  const [firstEducation, setFirstEducation] = React.useState<boolean>(false);
  const [secondEducation, setSecondEducation] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [uniqueId, setUniqueId] = React.useState();

  const handleReject = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const handleSuccess = () => {
    setOpenDialog(false);
    navigate(`/info/${uniqueId}`);
  };

  const isValid = (item: string) => {
    if (!firstEducation && item.includes("first_education")) {
      return false;
    }
    if (!secondEducation && item.includes("second_education")) {
      return false;
    }
    return true;
  };

  const handleTextFieldChange = (field: string, value: any) => {
    setStudentsInfo((prevInfo: any) => ({
      ...prevInfo,
      [field]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    if (studentsInfo.passport) {
      const uniqueId: any = sha256.hmac(secretKey, studentsInfo.passport).slice(0, 8);;
      setUniqueId(uniqueId);
      await setDoc(doc(db, "students", uniqueId), studentsInfo);
      setOpenDialog(true);
    }
  };

  const submitButtonValidation = () => {
    if (
      studentsInfo.first_name &&
      studentsInfo.second_name &&
      studentsInfo.passport
    ) {
      return false;
    }
    return true;
  };

  function GetSelectOptions(item: string) {
    if (item === "form_of_payment") {
      const menuItems = translation.translation.form_of_payment;
      return menuItems.map((item: string, index: number) => {
        return (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        );
      });
    }
  }

  function FormRow(item: string, index: number) {
    const FieldArea = () => {
      if (item === "form_of_payment") {
        return (
          <Select
            onChange={(e) => handleTextFieldChange(item, e.target.value)}
            fullWidth
            size="small"
          >
            {GetSelectOptions(item) as any}
          </Select>
        );
      } else if (item === "birth_date" || item === "first_education_diploma_date_of_issue" || item === "second_education_diploma_date_of_issue" || item === "date_order_creation" || item === "date_order_education" || item === "date_start_education") {
        return (
          <DatePicker
            onChange={(value) => {
              handleTextFieldChange(item, dayjs(value).format("DD/MM/YYYY"));
            }}
            format="DD/MM/YYYY"
            sx={{ width: "100%" }}
          />
        );
      } else {
        return (
          <TextField
            onChange={(e) => handleTextFieldChange(item, e.target.value)}
            value={studentsInfo[item]}
            multiline
            fullWidth
            size="small"
          />
        );
      }
    };

    return (
      <TableRow sx={{ display: isValid(item) ? "" : "none" }} key={index}>
        <TableCell sx={{maxWidth: 300}}>
          <Typography>{t(`table_rows.${item}`)}</Typography>
        </TableCell>
        <TableCell>{FieldArea()}</TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <Table sx={{ mb: 1 }} size="small">
        <TableBody>
          {Object.keys(rows).map((item, index) => {
            return FormRow(item, index);
          })}
        </TableBody>
      </Table>
      <Box sx={{ display: "grid" }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ display: firstEducation ? "none" : "" }}
          onClick={() => {
            setFirstEducation(true);
          }}
        >
          Перша освіта
        </Button>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ display: secondEducation ? "none" : "", marginTop: 1 }}
          onClick={() => {
            setSecondEducation(true);
          }}
        >
          Друга освіта
        </Button>
        <Button
          color="success"
          sx={{ marginTop: 1 }}
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="contained"
          disabled={submitButtonValidation()}
        >
          {t("success")}
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("success_notification_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("success_notification_sub_title")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject}>{t("success_notification_no")}</Button>
          <Button onClick={handleSuccess} autoFocus>
            {t("success_notification_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
