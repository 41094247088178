import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import PrintIcon from "@mui/icons-material/Print";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./firebase/firebaseInit";
import { useTranslation } from "react-i18next";

interface AppBarInterface {
  enablePrint?: boolean;
  handlePrint: () => void;
}

export default function AppBarComponent({
  handlePrint,
  enablePrint = true,
}: AppBarInterface) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("Logged out successfully");
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert("An unknown error occurred");
      }
    }
  };

  const handleRedirect = () => {
    handleLogout();
    navigate("/login");
  };

  return (
    <AppBar sx={{ width: "100%" }} position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h5">{t("web-name")} EDU</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            sx={{ display: enablePrint ? "" : "none" }}
            onClick={() => {
              handlePrint();
            }}
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleRedirect();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
