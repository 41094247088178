import React from "react";
import TableLayout from "../components/layout/TableLayout";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth } from "../components/firebase/firebaseInit";
import AuthLayout from "../components/layout/AuthLayout";
import { useTranslation } from "react-i18next";

export default function LoginPage() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:700px)");
  const { t, i18n } = useTranslation();

  const [login, setLogin] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const [error, setError] = React.useState<number>(0);
  const [errorDesc, setErrorDesc] = React.useState<string>("");

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  function handleLogin() {
    if (!isValidEmail(login)) {
      setErrorDesc("Please check your Email");
      setError(2);
    } else if (login.length < 1 || password.length < 1) {
      setErrorDesc("All field need to be field");
      setError(3);
    } else if (password.length < 8) {
      setErrorDesc("Password need to be 8 symbols long");
      setError(1);
    } else {
      setErrorDesc("");
      setError(0);
      // if success
      authenticationHandler();
    }
  }

  async function authenticationHandler() {
    try {
      await signInWithEmailAndPassword(auth, login, password);
      alert("Logged in successfully");
      navigate("/info/add");
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      } else {
        console.log("An unknown error occurred");
      }
    }
  }

  return (
    <AuthLayout>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: isMobile ? "100%" : "50%",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              p: 3,
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {t("web-name")}
            </Typography>
            <TextField
              error={error === 2 || error === 3}
              onChange={(e) => {
                setLogin(e.target.value);
              }}
              value={login}
              size="small"
              placeholder={t("auth_login")}
            />
            <TextField
              error={error === 1 || error === 3}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              size="small"
              placeholder={t("auth_password")}
            />
            <Typography sx={{ display: error !== 0 ? "" : "none" }}>
              {errorDesc}
            </Typography>
            <Button
              onClick={() => {
                handleLogin();
              }}
              variant="contained"
            >
              {t("auth_sign_in")}
            </Button>
          </Paper>
        </Box>
        <Box sx={{ width: "50%", display: isMobile ? "none" : "" }}></Box>
      </Box>
    </AuthLayout>
  );
}
