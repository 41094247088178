import { Box, Divider } from "@mui/material";
import React, { useRef } from "react";
import TableLayout from "../components/layout/TableLayout";
import HeaderComponents from "../components/HeaderComponents";
import TableComponent from "../components/TableComponent";
import AppBarComponent from "../components/AppBarComponent";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../components/firebase/firebaseInit";
import { collection, doc, getDoc } from "firebase/firestore";
import jsonData from "../locales/pl.json";

export default function StudentInfoTable() {
  const { t, i18n } = useTranslation();
  const { slug } = useParams<Record<string, string | undefined>>();
  const [student, setStudent] = React.useState<any>(null);
  const { table_rows } = jsonData.translation;

  const pageHeaderText = t("form_page_title");
  const headerText = t("title_form");
  const headerSubText = t("title_sub_form");

  const sortData = (
    data: Record<string, any>,
    order: string[]
  ): Record<string, any> => {
    const sortedData: Record<string, any> = {};

    order.forEach((key: string) => {
      if (data.hasOwnProperty(key)) {
        sortedData[key] = data[key];
      }
    });

    return sortedData;
  };
  
  



  React.useEffect(() => {
    if (slug) {
      const fetchStudent = async () => {
        try {
          const docRef = doc(collection(db, "students"), slug);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setStudent(sortData(docSnap.data(), Object.keys(table_rows)));
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error getting document:", error);
        }
      };

      fetchStudent();
    }
  }, [slug]);

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };

  return (
    <Box sx={{height: '100%'}}>
      <AppBarComponent handlePrint={handlePrint} />
      {student && (
        <TableLayout>
          <HeaderComponents text={pageHeaderText} />
          <Box ref={printRef}>
            <Box sx={{p: 2}}>
            <HeaderComponents fw={600} text={headerText} />
            <HeaderComponents fw={600} text={headerSubText} />
            </Box>
            <Divider />
            <TableComponent student={student} />
          </Box>
        </TableLayout>
      )}
    </Box>
  );
}
