import React from "react";
import { Typography, Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TableLayoutInterface {
  children: React.ReactNode;
}

export default function TableLayout({ children }: TableLayoutInterface) {
  const { t, i18n } = useTranslation();
  
  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: 5
        }}
      >
        <Container sx={{ pt: 1, height: "100%" }} component="main">
          {children}
        </Container>
        <Box component="footer" sx={{position: "fixed", bottom:0, left: 5}}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"© "}
            {new Date().getFullYear()}
            {` ${t("web-name")}`}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
