import React from "react";
import { Typography, Container, Box } from "@mui/material";

interface TableLayoutInterface {
  children: React.ReactNode;
}

export default function AuthLayout({ children }: TableLayoutInterface) {
  return (
    <>
      <Box
        sx={{
          backgroundImage: 'url("/auth.jpg")',
          backgroundSize: "cover",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container sx={{ pt: 1, height: "100%" }} component="main">
          {children}
        </Container>
        <Box component="footer">
          <Typography variant="body2" color="textSecondary" align="center">
            {"© "}
            {new Date().getFullYear()}
            {" Konto Studenta"}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
