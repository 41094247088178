import { Box, Typography } from "@mui/material";
import React from "react";

interface HeaderInterface {
  text: String;
  fw?: number;
}

export default function HeaderComponents({ text, fw = 400 }: HeaderInterface) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", textAlign:"center" }}>
      <Typography sx={{fontWeight: fw}} variant="h5">{text}</Typography>
    </Box>
  );
}
