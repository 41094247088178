import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";

interface TableInterface {
  student: any[] | null;
}

export default function TableComponent({ student }: TableInterface) {
  const { t, i18n } = useTranslation();

  const formatValue = (value: any) => {
    if (value instanceof Timestamp) {
      return value.toDate().toLocaleString();
    } else if (value instanceof Date) {
      return value.toLocaleString();
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    } else {
      return value;
    }
  };

  return (
    <Box>
      <Table size="small">
        <TableBody>
          {student &&
            Object.entries(student).map(([key, value], index) => (
              <TableRow key={index}>
                <TableCell sx={{border: "1px solid black", width: "30%"}} align="left">
                  <Typography variant="body1">
                    {t(`table_rows.${key}`)}
                  </Typography>
                </TableCell>
                <TableCell sx={{border: "1px solid black", width: "70%"}} align="left">
                  <Typography variant="body1">{formatValue(value)}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}
